<template>
  <div class="notification">
    <Header />
    <div class="card" v-for="item in list" :key="item.id">
      <div class="title">{{item.messName}}</div>
      <div class="time">{{item.createTime}}</div>
    </div>
    <InfiniteLoading 
      :distance="200" 
      spinner="waveDots"
      @infinite="getNotification" 
    >
      <div slot="no-more" class="infinite-loading-no-more">
        暂无更多
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import { fetchNotification } from '@/api/notification';
import Header from '../../components/header';
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    Header,
    InfiniteLoading,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      list: []
    }
  },
  methods: {
    /**
     * 获取通知列表
     */
    async getNotification($state) {
      try {
        const res = await fetchNotification({
          page: {
            pageNo: this.pageNum,
            pageSize: this.pageSize,
          }
        });
        this.pageNum++;
        this.list = this.list.concat(res.data);
        if (res.page.hasNextPage) {
          $state.loaded();
        } else {
          $state.loaded();
          $state.complete();
        }
      } catch (e) {
        $state.loaded();
        $state.complete();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  min-height: 100vh;
  background-color: #FAFBFC;
  padding-bottom: 32px;
  box-sizing: border-box;
  .card {
    width: 351px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    margin: 12px auto 0;
    padding: 15px 12px;
    text-align: left;
    box-sizing: border-box;
    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: #2C2C2C;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #818181;
      margin-top: 7px;
    }
  }
}
</style>